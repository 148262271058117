import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "outsmarting-contracts",
      "style": {
        "position": "relative"
      }
    }}>{`Outsmarting Contracts`}<a parentName="h1" {...{
        "href": "#outsmarting-contracts",
        "aria-label": "outsmarting contracts permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We will be running through a smart contract course created by OpenZeppelin, the team responsible for writing the most widely used and trusted smart contract libraries, and also one of the most respected audit and security firms in web3.`}</p>
    <p>{`There will be four lessons, and each will last for ~2hours, as the intention is to have a lot of space for shared explorations, questions, and a calm approach to learning about a new world.`}</p>
    <p>{`This guild is intended mostly for people who have never seen a smart contract before. It's not going to make you into a shadowy super coder, but it is going to introduce you to how networks like Ethereum actually work and uncover many of the more subtle aspects which are often skipped over in glib media pieces about the "future of money" or whatever other attention-grabbing title works best in that days A/B test.`}</p>
    <p>{`As always in Kernel, the content is secondary to the conversation we have around it. There are already numerous full answers to each of the Ethernaut puzzles which you can find yourself by simply googling it. However, what you won't get anywhere else is the depth of reflection and careful understanding we hope to cultivate together in this guild.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      